<template>
	<v-sheet color="transparent" class="pa-4">

		<NothingFound v-if="empty" what="questions"></NothingFound>
		<template v-else>
			<v-tabs v-model="tab" class="ml-3" color="grey">
				<v-tab>Questions</v-tab>
				<v-tab>Preview</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">
				<v-tab-item>
					<AssessmentQuestion 
					v-for="id in order" 
					:key="id" :question="questions[id]" :id="id"
					@up="move(id, 'up')"
					@down="move(id, 'down')"
					></AssessmentQuestion>
				</v-tab-item>
				<v-tab-item>
					<Preview :questions="order"></Preview>
				</v-tab-item> 
			</v-tabs-items>
		</template>
		<Saved :trigger="saved" @close="saved = false"></Saved>
		<p class="text-right">
			<v-btn color="primary" v-if="orderChanged" @click="saveOrder" class="mr-3">{{mwtr.SaveLayout}}</v-btn>
			<v-btn color="primary" to="/admin/questions/new">{{mwtr.NewQuestionNavItem}}</v-btn>
		</p>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import Saved from "@/components/notices/Saved"
	import AssessmentQuestion from "@/components/admin/panels/AssessmentQuestion"
	import NothingFound from "@/components/notices/NothingFound"
	import Preview from "@/components/survey/render/Preview"

	export default {
		name: "Questions", 
		props: {
			
		},
		data: () => {
			return {
				tab: 0,
				order: [],
				orderChanged: false, 
				saved: false
			}
		},
		components: {
			Saved,
			Preview,
			NothingFound,
			AssessmentQuestion
		},
		computed: {
			...Vuex.mapState({
				questions: state => state.questions
			}),
			empty(){
				return Object.keys(this.questions).length == 0
			},
			headers(){
				return [
					{value: "title", text: "Title"},
					{value: "button", text: " "},
				]
			}, 
		},
		methods: {
			move( id, direction ){
				this.mwutilities.moveItemInArray(id, this.order, direction);
				this.orderChanged = true;
			},
			getOrder(){
				var options = this.mwutilities.getPositions(this.questions)
				return options;					
			},
			saveOrder(){
				this.order.forEach( ( key, i) => {
					this.$store.dispatch("updateDoc", {
						doc: `questions/${key}`,
						data: {position: i}
					}).then( ( ) => {
						this.saved = true;
						this.orderChanged = false;
					})
				} )
			}
		},
		watch: {
			questions: {
				immediate: true,
				handler(){
					this.order = this.getOrder();
				}
			}, 
		},
		created(){
			this.$store.dispatch("questions/fetchAll")
      }

	}
// </script>"