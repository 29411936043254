<template>
	<v-card color="secondary" class="mb-3">
		<v-card-title>{{question.title}}</v-card-title>
		<v-card-text>
			{{question.description}}
			<!-- <v-chip color="secondary">{{question.type}}</v-chip> -->
		</v-card-text>
		<v-card-actions>
			<v-btn color="grey" icon @click="$emit('up')"><v-icon>mdi-arrow-up-circle</v-icon></v-btn>
			<v-btn color="grey" icon @click="$emit('down')" class="ml-0"><v-icon>mdi-arrow-down-circle</v-icon></v-btn>
			<v-spacer></v-spacer>
			<ListButtons :id="id" collection="questions"></ListButtons>
		</v-card-actions>
	</v-card>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">

	import ListButtons from "@/components/admin/lists/ListButtons"
	export default {
		name: "AssessmentQuestion",
		props: {
			id: {},
			question: {}, 
		},
		data: () => {
			return {

			}
		},
		components: {
			ListButtons
		},
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"