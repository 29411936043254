<template>
<v-alert color="secondary">
	No {{what}} found.
</v-alert>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	// import Vuex from "vuex";
	// import - from "@/components/"
	export default {
		name: "NothingFound", 
		props: {
			what: {}
		},
		data: () => {
			return {

			}
		},
		// components: {

		// },
		// computed: {
			// ...Vuex.mapState({})
		// },
		// methods: {
		// }
		// created(){
			
		// }

	}
// </script>"